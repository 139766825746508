.navigation-box li {
  cursor: pointer;
}
.lgnbtnotp {
  background-color: #005499;
}
.lgnbtnotp :hover {
  background-color: #ef4e22 !important;
}

/* dashboard */

.blocktoggle {
  display: block;
}
.blocktogglenone {
  display: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
