.page-main {
  flex: 1 1 auto;
}

.app-content.page-body .side-app {
  padding: 6.2rem 0.75rem 0 0.75rem !important;
}

.app-header .header-brand {
  color: inherit;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
  font-weight: 600;
  padding: 0;
  transition: 0.3s opacity;
  line-height: 2.9rem;
  min-width: 220px;
  text-align: center;
}

.lh-1 {
  line-height: 1rem;
}

.app-header .header-brand .header-brand-img {
  margin-right: 0;
}

.app-header1 .header-brand {
  color: inherit;
  margin-right: 0;
  font-size: 1.25rem;
  white-space: nowrap;
  font-weight: 600;
  padding: 0;
  transition: 0.3s opacity;
  line-height: 2.9rem;
  min-width: 215px;
  text-align: center;
}

.app-header1 .header-brand .header-brand-img {
  margin-right: 0;
}

.header-brand:hover {
  color: inherit;
  text-decoration: none;
}

.header-brand-img {
  height: 2.5rem;
  line-height: 2rem;
  vertical-align: bottom;
  margin-right: 0;
  width: auto;
}

.desktop-lgo {
  height: 2rem !important;
}

@media (max-width: 768px) {
  .header-brand-img {
    display: none;
  }
  .header-brand {
    order: 2;
  }

  .img_poll img {
    width: 304px !important;
    height: 389px !important;
  }
  .header-brand {
    min-width: 30px !important;
  }

  .app-header .header-brand-img.mobile-logo {
    display: block !important;
    width: 140px;
  }
  .hidem {
    display: none;
  }

  #search-bar {
    display: none;
  }

  .header-notify {
    display: none;
  }

  .header-expand {
    display: none;
  }

  #tts-body-minify {
    padding-left: 1.5rem !important;
  }

  #listen-text {
    margin-right: 0 !important;
    margin-bottom: 1rem;
  }
}

.header-brand {
  color: inherit;
  margin-right: 0;
  font-size: 1.25rem;
  white-space: nowrap;
  font-weight: 600;
  padding: 0;
  transition: 0.3s opacity;
  line-height: 2.6rem;
}

.header-brand:hover {
  color: inherit;
  text-decoration: none;
}

.header-brand-img {
  height: 2.5rem;
  line-height: 2rem;
  vertical-align: bottom;
  margin-right: 0;
  width: auto;
}

@media (max-width: 992px) and (min-width: 768px) {
  .header-brand-img.desktop-lgo {
    margin-left: 2rem;
  }

  .hor-header .header-brand-img.desktop-lgo,
  .header-brand-img.dark-logo {
    margin-left: 1rem;
  }

  .animated-arrow.hor-toggle {
    text-align: center;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1.2rem;
    position: relative;
    border: 1px solid #ebecf1;
    border-radius: 50px;
    margin: 5px;
    top: 0;
  }

  .animated-arrow.hor-toggle span {
    margin-left: 8px;
    margin-top: 2px;
    vertical-align: middle;
  }
}

@media (min-width: 992px) {
  .top-header.hor-top-header .header-brand-img {
    display: none;
  }

  .top-header.hor-top-header .header-brand.center-logo .desktop-lgo {
    left: 0;
    display: block;
    position: absolute;
    margin: 0 auto;
    right: 0px;
  }

  .top-header.hor-top-header .form-inline .form-control {
    width: 175px;
  }

  .top-header.hor-top-header .header-brand {
    margin-right: 0rem;
  }

  .search-element {
    margin-top: 4px;
  }

  .sticky-pin {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
  }
}

.sidenav-toggled .header.py-4 .header-brand .header-brand-img.desktop-lgo {
  display: flex !important;
  margin: 0;
}

@media (max-width: 768px) {
  .header-brand-img {
    display: none;
  }

  .header-brand {
    min-width: 30px !important;
  }

  .app-header .header-brand-img.mobile-logo {
    display: block !important;
    height: 2rem;
  }

  #search-bar {
    display: none;
  }

  .header-notify {
    display: none;
  }

  .header-expand {
    display: none;
  }

  #tts-body-minify {
    padding-left: 1.5rem !important;
  }

  #listen-text {
    margin-right: 0 !important;
    margin-bottom: 1rem;
  }
}

.app-header .header-brand .header-brand-img {
  margin-right: 0;
}

.header-brand-img {
  height: 2.5rem;
  line-height: 2rem;
  vertical-align: bottom;
  margin-right: 0;
  width: auto;
}

@media (max-width: 991px) {
  .top-header.hor-top-header .header-brand.center-logo .desktop-lgo {
    display: none;
  }

  .header.top-header.hor-top-header {
    border-bottom: 0px;
  }
}

@media (min-width: 991px) {
  .hidetoggleds {
    display: none;
  }

  .gapheaderitem {
    gap: 20px;
  }

  .itemheader {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    color: #fff;
  }

  .itemheader:hover {
    color: #000000;
    background-color: #fbb03b;
    border-radius: 5px;
  }
  .activeNav {
    background-color: #fbb03b;
    color: #000000 !important;
    border-radius: 5px;
    font-weight: bolder;
  }
  .mySurveys {
    color: #ef4e22 !important;
    font-weight: 500;
  }
  .mySurveys:hover {
    color: #ef4e22 !important;
    font-weight: 500;
  }
}

.order-lg-2 {
  order: 2;
}

.profile-dropdown.show .dropdown-menu[x-placement^="bottom"] {
  left: -130px !important;
}

.reward-dropdown.show {
  left: -130px !important;
}

.dropdown {
  display: block;
}

.header .profile-dropdown .dropdown-menu {
  top: 61px !important;
}

.header .profile-dropdown .dropdown-menu .dropdown-item {
  padding: 0.5rem 1.1rem;
}

.header .profile-dropdown .dropdown-menu .dropdown-item:hover,
.header .profile-dropdown .dropdown-menu .dropdown-item:focus {
  background: #f5f9fc;
}

.profile-dropdown {
  cursor: pointer;
  height: 100%;
  /* background-color: #3A81D6;
  border-radius: 10px; */
}

.country {
  height: 80%;
  /* background-color: #fff; */
  border-radius: 5px;
  text-align: center;
  margin-left: 5px;
}
.country img{
  display: block;
  margin: 3px 3px 0 3px;
}
.country span{
  display: block;
  font-size: 0.7rem;
  margin: 0 auto;
}

.profile-dropdown img {
  width: 50px;
  height: 50px;
  /* border: 3px solid #ebecf1; */
  /* border-radius: 50%; */
  margin: 10px 5px;
  /* padding: 5px; */
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2),
    0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-dropdown .nav-link {
  padding: 5px 0 0;
}

*::-ms-backdrop,
.app-sidebar-help {
  display: none;
}

.side-menu__icon {
  color: #1e1e2d;
}

.main_content_gts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-align: center; */
}

.view_all_survey {
  border-radius: 5px;
  color: black;
  font-weight: 500;
  text-align: center;
  background-color: #ef4e22;
  width: 120px;
  height: 50px;
}

.main_content_gts h1 {
  font-size: 30px;
  color: #fff;
  font-weight: normal;
}
.viewall{
  color: orange;
  font-size: 14px;
  font-weight: normal;
}

.list_item_survey {
  background-color: #e8e8ee;
  text-align: center;
  padding: 8px;
  border-radius: 5px;
}

.list_item_survey h1 {
  font-size: 18px;
  font-weight: 400;
}

.list_item_survey h2 {
  font-size: 18px;
  font-weight: 400;
}

.list_item_survey p {
  font-size: 25px;
  font-weight: 400;
}

.just_text {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}

.just_text_history {
  font-size: 22px;
  font-weight: 600;
}

.convert_points h1 {
  font-size: 18px;
  font-weight: 500;
}

.convert_points {
  text-align: center;
  align-items: center;
  background-color: #e8e8ee;
  padding: 30px;
}
.update_profile img {
  width: 40px;
  height: 40px;
  border: 1px solid #ebecf1;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2),
    0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  margin-top: -3px;
  object-fit: cover;
}
.update_profile {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
}
.update_profile h1 {
  color: blue;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  padding-left: 10px;
}
.side_menu_list {
  margin-top: 6px;
  cursor: pointer;
  background-color: #051a39;
  border: 1px solid #255389;
  border-radius: 5px;
}

.sideSubMenu {
  height: 75px !important;
}

.side_menu_list_active {
  background-color: #3a81d6 !important;
}
.side-menu > .active {
  background-color: #3a81d6 !important;
}
.profile_img {
  background-color: #9f9fa3;
  padding: 15px;
  border-radius: 0px 50px 50px 0px;
}
.side_menu_list p {
  margin: 0;
  margin-bottom: 0px;
  line-height: 1.1rem;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
.points{
  font-size: 12px !important;
  color: orange !important;
}
.side_menu_list .circle_side_menu h4 {
  text-align: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  align-self: center;
  background-color: #08f513;
}
.side_menu_list .circle_side_menu1 h4 {
  text-align: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  align-self: center;
  background-color: #dad746;
}
.side_menu_list .circle_side_menu2 h4 {
  text-align: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  align-self: center;
  background-color: #08e5f5;
}
.side_menu_list .circle_side_menu3 h4 {
  text-align: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  align-self: center;
  background-color: #2c08f5;
}

.circle_side_menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle_side_menu1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle_side_menu2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle_side_menu3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_poll img {
  width: 450px;
  height: 250px;
}
.img_poll {
  text-align: center;
}
.dropdown_profile_update {
  position: absolute;
  margin-top: 75px;
  z-index: 99999;
  width: 250px;
  left: 0;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffffff;
  /* border: 1px solid gray; */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.dropdown_reward_update{
  left: -130px;
}
.dropdown_profile_update .profile_update_tab h1 {
  color: blue;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
}
.dropdown_profile_update .profile_update_tab p {
  margin: 0;
  line-height: 1.5rem;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}
.profile-dropdown-list img {
  width: 60px;
  height: 60px;
  background: transparent;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2),
    0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);

  object-fit: cover;
}

.profile-dropdown-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-message {
  background-color: rgb(255, 255, 0);
  z-index: 9999;
  position: relative;
  width: 100%;
  height: 24px;
  text-align: center;
  font-weight: bold;
}
.counter {
  min-height: 40px;
  border: 0.5px solid rgb(238, 231, 231);
  cursor: pointer;
  border-radius: 5px;
  /* background-color:rgb(250, 245, 245) !important; */
}
.counter_bg {
  background-color: #ffa50030 !important;
}
.check_product {
  color: #ffffff;
  position: absolute;
  float: right;
  right: -5px;
  top: -5px;
  font-size: 17px;
  background: linear-gradient(
    90deg,
    #f15a24 0%,
    #f3712a 32%,
    #faaf3a 99.3%,
    #fbb03b 100%
  );
  border-radius: 15px;
  height: 24px;
  width: 24px;
  padding: 2px;
  box-shadow: 0 0 4px #fff;
}

.counter-inner {
  position: relative;
  z-index: 2;
}
.counter .counter-inner h6 {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #031b4e;
}
.counter .counter-inner h6:hover {
  color: #f3a033;
}

.counter img {
  width: 80px;
  padding-top: 7px;
  transition: all 0.3s ease;
}
/* .counter:hover{
  color: #f3a033 ; 
   img{
    transform: scale(1.08);
} */

.gap_product {
  gap: 10px;
}

.blocktoggle {
  display: block !important;
  transition: ease-in !important;
}
.blocktogglenone {
  display: none !important;
  transition: ease-in !important;
}
.blackAnchor > a,
.blackAnchor > a:hover,
.blackAnchor > a:focus,
.blackAnchor > a:active {
  color: blue !important;
  text-decoration: none;
  background-color: transparent;
}

.UnderConstruction img {
  width: 350px;
  border-radius: 5px;
}
.UnderConstruction h1 {
  font-size: 28px;
}

/* user segmentation */

.SearchResults-module_list_2AV2c {
  /* // width: 100%;
  // display: flex;
  // flex-wrap: wrap;
  // margin-top: 10px; */
}

.segment_sec {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.SearchResults-module_result_3-uXL {
  /* // width: 25%; */
}
.SearchResults-module_result_3-uXL {
  display: flex;

  position: relative;
  /* // text-align: center;
  // width: 20%;
  // box-sizing: border-box; */
}
a:not(img) {
  text-decoration: none;
  color: #5672c4;
  vertical-align: baseline;
  cursor: pointer;
}
.ContentCard-module_card_1xFCo {
  position: relative;
  background-color: #ffffff;

  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 1px 2px 0 rgb(0 0 0 / 24%);
  display: inline-block;
  /* // margin: 7px; */
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-width: 280px;
  max-width: 280px;
  max-height: 335px;
  transition: box-shadow 0.2s;
  /* // margin-bottom: 20px; */
}
.title-surveyPage-gts {
  margin-left: 10px;
  cursor: pointer;
}
.ContentCard-module_link_bi8gq {
  display: block;
  /* width: 230px; */
  height: 270px;
}
.ContentCard-module_front__1Cvl {
  box-sizing: border-box;
  width: 280px;
  height: 270px;
  left: 0;
  overflow: hidden;
  white-space: normal;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.ContentCard-module_image_1D6-7.ContentCard-module_narrow_1Ktvs {
  background-color: #faf5ed;
  /* // height: 172px; */
  margin: 0px auto 0;
  width: 100%;
  height: 100%;
}
.ContentCard-module_image_1D6-7.ContentCard-module_narrow_1Ktvs img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  /* max-height: 100%; */
  object-fit: cover;
}
.ContentCard-module_image_1D6-7 {
  position: relative;
  background-color: #fff;
  border: 0;
  box-sizing: border-box;
  /* // height: 192px; */
  overflow: hidden;
}
.ContentCard-module_titleArea_sGOV3 {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
  box-sizing: border-box;
  height: 68px;
  padding: 3px 20px 0;
  position: relative;
  text-align: left;
}
.font-primary-bold {
  font-family: "Montserrat";
  font-weight: 500;
}
.Title-module_title_21JAg {
  color: #464646;
  font-size: 18px;

  margin-top: 10px;
  overflow: hidden;
  margin-bottom: 0px;
}
.ContentType-module_contentTypeHolder_7Ku6w {
  bottom: 16px;
  color: #878787;
  font-size: 14px;
  line-height: 1.375;
  left: 20px;
  position: absolute;
  text-transform: capitalize;
}
.SearchResults-module_result_3-uXL {
  transition: none;
  opacity: 1;
}
.SearchResults-module_result_3-uXL:hover
  .ContentCard-module_hoverSection_2GeOZ {
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
  box-sizing: border-box;
  color: #464646;
  display: flex;
  flex-direction: column;
  white-space: normal;
  width: 280px;
  height: 300px;
  margin-top: 50px;
  opacity: 1;
  padding: 10px 20px 0;
  text-align: left;
  transform: translateY(-20px);
  transition: translateY(-20px);
  transition: transform 0.3s, opacity 0.15s;
  z-index: 2;
  pointer-events: none;
}
.font-primary-bold {
  font-family: "Montserrat";
  font-weight: bold !important;
  font-size: 16px;
  color: #0c3283;
}
.point-surveyPage {
  margin-top: 0px;
  font-size: 16px;
  color: #ef4e22;
  margin-left: 10px;
}
.ContentCard-module_description_1ejK0 {
  font-size: 14px;
  line-height: 1.375;
  height: 100%;
  margin-bottom: 4px;
  overflow: hidden;
}
.ContentCard-module_categories_343y- {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ContentType-module_contentTypeHolder_7Ku6w {
  bottom: 16px;
  color: #878787;
  font-size: 14px;
  line-height: 1.375;
  left: 20px;
  position: absolute;
  text-transform: capitalize;
}
.ul_style {
  list-style-type: none;
  padding-left: 5px !important;
}
.ul_style > li::before {
  content: "-";
  text-indent: -5px;
  margin-right: 3px;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}
.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #405189;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.avatar-title1 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #408964 !important;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.avatar-title2 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #4d64af !important;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.avatar-title3 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.bg-soft-info {
  background-color: #ef4e22d2;
}

.card-animate {
  background-color: white;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12) !important;
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12) !important;
}

.card-animate:hover {
  -webkit-transform: translateY(calc(-1.5rem / 5));
  transform: translateY(calc(-1.5rem / 5));
  -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12) !important;
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12) !important;
}
.text-muted {
  font-size: 15px;
  font-weight: 600;
}
.avatar-sm .fa {
  font-size: 22px;
}
.counter-value {
  font-size: 18px !important;
}
.swal2-popup {
  font-size: 0.6rem !important;
  font-family: Georgia, serif;
}
.w-20 {
  width: 20% !important;
}
.w-80 {
  width: 80% !important;
}

.addPadding {
  padding: 4px;
}
.gts-privacyPolicy {
  margin-top: 110px;
}
.react-datepicker-popper {
  z-index: 9 !important;
}
.countdown-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
}

.countdown-text > p {
  font-size: 0.8rem;
  font-weight: 500;
  color: #637381;
}

.countdown-text > button {
  background-color: transparent;
  border: none;
  color: #ff5630;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
.header-logo {
  padding: 5px;
  padding-left: 3rem;
}
.w-85 {
  width: 85% !important;
}
.w-15 {
  width: 15% !important;
}
.sidebar-right {
  margin-top: 60px !important;
  right: 0;
  background-color: white;
}
.sidebar-right-transition {
  transition: left 0.3s ease, width 0.3s ease;
}
.w-60 {
  width: 60% !important;
}
.sectionStyle {
  background: linear-gradient(
    90deg,
    rgb(0, 0, 0, 0.3) 10%,
    rgba(0, 0, 0, 0.25) 29.4%,
    rgba(0, 0, 0, 0.20) 64.4%,
    rgba(0, 0, 0, 0.15) 88.6%,
    rgba(0, 0, 0, 0) 100%
  );
}
.text-gotosample {
  color: #fefdff;
}
.lgnbtnNew {
  background: linear-gradient(
    90deg,
    #f15a24 0%,
    #f3712a 32%,
    #faaf3a 99.3%,
    #fbb03b 100%
  );
  border-radius: 10px;
  background-blend-mode: hard-light;
  mix-blend-mode: hard-light;
}
.btnOrngGrd{
  background: linear-gradient(
    90deg,
    #f15a24 0%,
    #f3712a 32%,
    #faaf3a 99.3%,
    #fbb03b 100%
  );
  border-radius: 5px;
  background-blend-mode: hard-light;
  mix-blend-mode: hard-light;
  color:#fff !important;
  padding: 10px 25px !important;
  font-weight: 700 !important;
}
.lgnbtnBack {
  font-size: 16px;
  height: 38px;
  font-weight: 500;
  background-color: #3a81d6;
  border: none;
  border-radius: 3px;
  width: 100%;
  border-radius: 10px;
  background-blend-mode: hard-light;
  mix-blend-mode: hard-light;
}
.lgnbtnBack:hover,
.lgnbtnBack:focus {
  background-color: #3a81d6;
  box-shadow: 0px 0px 8px #005499;
}
lgnbtnBack:disabled {
  background-color: #6c757d;
  box-shadow: none;
}

.radio_button {
  float: left;
  height: auto;
  width: 40px;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  padding: 22px;
}

.radio_button label,
.radio_button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  color: #222;
  margin: 0;
}

.radio_button input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.radio_button input[type="radio"]:checked + label {
  background: #fbb03b;
  border-radius: 4px;
  font-weight: 700;
}

.radio_button label {
  cursor: pointer;
  z-index: 90;
}
.clrb{
  clear: both;
}
hr.orng{
  border: 1px solid #FBB03B;
  border-radius: 1px;
  display: inline-block;
}
.alnC{
  text-align: center;
}
.alnR{
  text-align: right;
}
.alnL{
  text-align: left;
}
.cw95p{
  width: 95%;
}
.cw90p{
  width: 90%;
}
.cw85p{
  width: 85%;
}
.cw80p{
  width: 80%;
}
.cw75p{
  width: 75%;
}
.cw70p{
  width: 70%;
}
.cw60p{
  width: 60%;
}
.cw55p{
  width: 55%;
}
.cw50p{
  width: 50%;
}
.cw40p{
  width: 40%;
}
.cw30p{
  width: 30%;
}
.cw20p{
  width: 20%;
}
.cw10p{
  width: 10%;
}
.courselImage{
  position: relative;
  margin-right: 0 !important;
}
.cSecOne{
  position: relative;
  padding: 80px 0 50px 0;
  background-color: #122653 !important;
}
.cSecTwo{
  position: relative;
  padding: 80px 0 50px 0;
  background-color: #0A1B3A !important;
}
.bg-col-two{
  background-color: #0A173F !important;
  padding-bottom: 30px;
  background-repeat: no-repeat;
  min-height: 100vh;
}
span.whit{
  color:#fff;
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  display: inline-block;
  line-height: 0.8;
}
span.orng{
  color:#FBB03B;
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  display: inline-block;
  line-height: 0.8;
}
p.smWhitTxt{
  color:#fff;
  font-weight: 500;
  line-height: 23px;
}
.whtTxt{
  color: #fff;
}
.subTxt{
  color:#FBB03B;
  font-size: 1rem;
  display: inline-block;
}
p.smFS{
  color:#fff;
  font-size: 0.9rem;
  line-height: 20px;
}
span.smHeading{
  font-size: 1.05rem;
  color: #fff;
}
.ml50x{
  margin-left: 50px;
}
.frmlgn .main-panel__title, .main-panel__title{
  color:#f2f2f2;
  font-weight: 600;
}
.otpTxt{
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
}
.banner-one__title{
  margin: 0;
  padding: 0;
}
.dummyPerson{
  position: relative;
  right:70px;
}
h3.banner-one__title {
  line-height: 0.8;
  margin-top: 15px;
}
.slidePadding{
  padding: 150px 0 200px 0;
}
.owl-dot span{
  background-color: #fff !important;
}
.owl-dot.active span{
  background-color: #FBB03B !important;
  width: 13px !important;
  height: 13px !important;
  box-shadow: #f2f2f2 0 0 7px;
}
.ProgressBar{
  width:93%;
  margin: 80px auto 0;
  height: 1px;
  background-color: hsla(0,0%,82.7%,.6);
}
.annotOuter{
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  display: inline-block;
  position: relative;
  top: -12px;
  left: -10px;
}
.annotation{
  height: 12px;
  width: 12px;
  background-color: #FBB03B !important;
  border-radius: 6px;
  margin: 3px auto 0;
}
.mark{
  margin-right: 22%;
}
ul.ul2{
  color:#fff;
  width: 100%;
  margin: 35px auto 0;
}
ul.ul2 li span.heading{
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 700;
}
ul.ul2 li span{
  color:#fff;
  display: block;
}
ul.ul2 li{
  width: 22%;
  display: inline-block;
  margin-right: 3%;
  vertical-align: top;
}

ul.ul2 li span.heading{
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 700;
}
ul.ul2 li span.txt{
  font-size: 0.8rem;
}
ul.ul2 li::marker {
  box-shadow: #f2f2f2 0 0 7px;
}

ol.classyOL{
  width: 100%;
  margin: 50px auto 0;
  list-style-type: decimal;
  list-style-position: inside;
  counter-reset: headings;
}
ol.classyOL li{
  width: 25%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.20),
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.10),
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.0)
  );
  display: inline-block;
}
ol.classyOL li::before {
  counter-increment: headings;
  content: counter(headings, decimal); 
  color:#fff;
  display: inline-block;
  font-size: 5rem;
  font-weight: 700;
  position: relative;
  top:0px;
  right: 15px;
}
ol.classyOL li span{
  color: #fff;
  font-size: 0.85rem;
  width: 80%;
  display: inline-block;
  line-height: 18px;
}
.txtOrng{
  color: #FBB03B !important;
}
.newsletter-tbox{
  width:30%;
  display: inline-block;
  border-radius: 10px 0 0 10px;
  height: 43px !important;
  border: none;
}
.newsletter-btn{
  width:15%;
  display: inline-block;
  border-radius: 0 10px 10px 0;
  height: 43px !important;
}
.pf-form__textfield{
  text-align: center !important;
}
.footer-widget__title{
  color: #fff;
  text-transform: uppercase;
}
.ft-copy{
  color: #fff;
  font-size: 0.7rem;
}
.ftrLink{
  color:#f4f4f4 !important;
  font-size: 0.85rem !important;
  margin-left: 10px;
}
.ftrLink:hover{
  color:#FBB03B !important;
}
.ftrLine{
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 10px;
  padding-top: 15px;
}
ul.ftrSocial{
  width: 100%;
}
ul.ftrSocial li{
  display: inline-block;
  width: 25px;
  text-align: center;
  vertical-align: bottom;
  margin-right: 10px;
}
ul.ftrSocial li.sclT img{
  width: 27px;
}
ul.ftrSocial li.sclF img{
  width: 12px;
}
ul.ftrSocial li.sclL img{
  width: 25px;
}
ul.ftrSocial li.sclI img{
  width: 25px;
}
.form-check-label{
  color:#fff;
}
.prfLink b, .prfLink2 b{
  display: inline-block;
  color:#333;
  line-height: 15px !important;
  font-size: 0.7rem;
}
.prfLink span, .prfLink2 span{
  line-height: 15px !important;
  color:#333;
  font-size: 0.6rem;
  display: inline-block;
}
.rt180d{
  transform: rotate(180deg); 
}
.analyticVal, .analyticLbl{
  display: inline-block;
  width: 100%;
  letter-spacing: -1px;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif !important;
}

.stylishCard::before{
  content: " ";
  position: absolute;
  left:9px;
  display: inline-block;
  border-radius: 2px;
  height: 75px;
  width: 6px;
  top:24px;
  z-index: 1;
}

div.stylishCard .analyticVal{
  font-weight: 700;
  font-size: 2rem;
  line-height: 22px;
}

div.stylishCard .analyticLbl{
  font-weight: 500;
  font-size: 1rem;
  line-height: 22px;
}

div.stylishCard .analyticST{
  font-weight: normal;
  font-size: 0.7rem;
  line-height: 15px;
  display: inline-block;
}

div.stylishCard:nth-of-type(1)::before {
  background-color: #F7931E;
}

div.stylishCard:nth-of-type(1) .analyticVal{
  color:#F7931E;
}

div.stylishCard:nth-of-type(2)::before {
  background-color: #31A3AA;
}

div.stylishCard:nth-of-type(2) .analyticVal{
  color:#31A3AA;
}

div.stylishCard:nth-of-type(3)::before {
  background-color: #4D5FFE;
}

div.stylishCard:nth-of-type(3) .analyticVal{
  color:#4D5FFE;
}

div.stylishCard:nth-of-type(4)::before {
  background-color: #3A81D6;
}

div.stylishCard:nth-of-type(4) .analyticVal{
  color:#3A81D6;
}

.stylishCard .card{
  z-index: 0;
}
.btnBlueGrd{

  background-color: #3A81D6; 
  border-radius: 5px;
  color:#fff !important;
  padding: 10px 25px !important;
  font-weight: 700 !important;

}

.exPDBtn{
  padding: 10px 35px !important;
}
.btnArwF::after{
  background-image: url('../public/home/front/assets/images/RightArrow.png');
  background-repeat: no-repeat;
  content: "";
  display: inline;
  padding: 0px 20px;
  margin-left: 10px;
}

.btnArwB::before{
  background-image: url('../public/home/front/assets/images/LeftArrow.png');
  background-repeat: no-repeat;
  content: "";
  display: inline;
  padding: 0px 20px;
  margin-right: 10px;
}
.txtUC{
  text-transform: uppercase;
}
.fixstyle{
  width: 209%;
  position: relative;
}
.text-delete{
  color:#dc3545;
}
.text-delete:hover{
  color:#df2235;
}
/* .tab-content{
  border: 3px solid #F7931E;
} */
.rs-table-cell-header > .rs-table-cell > .rs-table-cell-content{
  font-size: 1rem;
  color:#333;
  font-weight: 600;
}
.rs-table-cell-group > .rs-table-cell > .rs-table-cell-content{
  font-weight: normal !important;
}
.secHeader, .nav-link.active{
  border-bottom: 3px solid #F7931E;
  color: #fff; 
  margin: 10px 0;
  font-size: 1.2rem;
  display: inline-block;
}
.nav-link{
  color: #fff; 
  margin: 5px 0 ;
  font-size: 1.2rem;
  display: inline-block;
}

.nav-link.tablink, .nav-link.tablink.active{
  margin: 20px 0 0;
  border-radius: 0;
}
.secSeparator{
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
}
.divRnd{
  border-radius: 5px;
}
.welcomeTxt{
  font-size:0.9rem !important;
  font-weight: 700;
}
.w100px{
  width:115px;
}
.btnH30px{
  height: 38px;
}
.frmLbl{
  color:#fff;
  font-size: 0.8rem;
  margin-bottom: 0;
}
.surveyBanner{
  max-width:100%;
}

.coupon-card{
  /* background: linear-gradient(135deg, #7158fe, #9d4de6); */
  background-color: #3A81D6;
  color: #fff;
  text-align: center;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.15);
  position: relative;
  padding-bottom: 10px;
  width: 100%;
}
.logo{
  width: 100%;
}
.coupon-card h3{
  font-size: 25px;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 10px;
}
.coupon-card p{
  font-size: 13px;
}
.coupon-card p.cpnDesc{
  line-height: 15px;
  padding: 0 10px;
}
.coupon-card p.cpnPoints{
  background-color: #051a39;
  border: 1px solid #255389;
  padding: 7px 0;
  font-size: 15px;
  font-weight: 700;
}
.coupon-card p.cpnBalPoints{
  background-color: #051a39;
  border: 1px solid #255389;
  font-size: 12px;
  padding: 5px 0;
}
.coupon-row{
  display: flex;
  align-items: center;
  margin: 25px auto;
  width: fit-content;

}
#cpnCode{
  border: 1px dashed #fff;
  padding: 10px 20px;
}
#cpnMsg{
  border: 1px dashed #fff;
  padding: 10px 20px;
  font-size: 0.8rem;
}
#cpnBtn, .cpnBtn{
  border: 1px solid #fff;
  background: #fff;
  padding: 10px 20px;
  color: #7158fe;
  cursor: pointer;
}
.circle1, .circle2{
  position: absolute;
  top: 75%;
  height: 60px;
  width: 30px;
  background-color: transparent;
  border-top: dashed 1px #fff;
  border-bottom: dashed 1px #fff;
}
.circle1{
  left:5.5%;
  border-radius: 0 30px 30px 0 ;
  transform: translate(-50%, -50%);
  border-right: dashed 1px #fff;  
}
.circle2{
  right:-5.5%;
  border-radius: 30px 0 0 30px;
  transform: translate(-50%, -50%);
  border-left: dashed 1px #fff;
}
.chartTrack{
  background-color: #fff; width: 170px; margin: 0px auto; height: 8px; border-radius: 4px;
}
.chartTrackProfile{
  width: 100% !important;
  margin: 0 !important;
  font-size: 0.7rem;
}
.profPercent{
  font-size: 0.8rem;
  color:#fff;
  position: relative;
  top: -5px;
}
.barChart{
  background-color: orange;height: 8px; width: 0%;border-radius: 4px 0 0 4px;
}
.check_product::before{
  position: relative;
  font-size: 17px;
  color: #fff;
  font-weight: 700;
  left: 1px;
  top: 1px; 
}
.ruleBox{
  padding: 20px 10px;
  border-radius: 5px;
  color:#fff;
}
.redeemBox{
  padding: 17px 10px;
  border-radius: 5px;
  color:#fff;
}
.blueGradient{
  background: linear-gradient(90deg, rgba(17,161,248,1) 0%, rgba(41,173,252,1) 60%, rgba(112,228,252,1) 100%);
}
.boxBorder{
  border: dashed 1px #fff;
}
.ruleBox h2, .redeemBox h2{
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.ruleBox ul{
  padding-left: 25px;
  padding-top: 15px;
  margin-bottom: 0;
}
.ruleBox ul li{
  font-size: 0.9rem;
  margin-bottom: 12px;
}
.redeemTxt:disabled{
  background: transparent;
  border:none;
  color:#fff;
  padding: 0;
  height: 30px;
  display: inline;
  width: 90%;
}
.pwdSA{
  font-weight: 700;
}
.redeemSP::before{
  content: "\20B9";
  margin-right: 5px;
}
.referral{
  color: #fff;
}
.ref-h1{
  color:#fff;
}
.ref-h2{
  color:#fff;
}
.ref-small{
  font-style: italic;
  font-size: 1.2rem;
}
.ref-small::before{
  content: open-quote; 
  font-size: 2rem;
  margin-right: 5px;
  font-weight: 600;
}
.ref-small::after{
  content: close-quote; 
  font-size: 2rem;
  margin-left: 5px;
  font-weight: 600;
}
.refDestImg{
  text-align: center;
  display: inline-block;
}
.refDestImg img{
  width: 60%;
}
.refDestTxt{
  text-align: center;
  font-size: 0.8rem;
  margin-top: 10px;
  font-weight: 600;
  display: inline-block;
  width: 100%;
}
.shTD{
  background-color: orange;
  color: #fff;
  padding: 8px 0 8px 4px;
  font-size: 0.9rem;
  font-weight: 600;
}
.fTD{
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
  border-left: solid 1px #eee;
}
.lTD{
  border: solid 1px #eee;
}
.row:nth-child(odd) .dTD{
  background-color: #ddd;
}
.row:nth-child(even) .dTD{
  background-color: #eee;
}
.dTD{
  color: #333;
  padding: 8px 0 8px 4px;
  font-size: 0.9rem;
  font-weight: 600;
}
.smallWhiteTxt{
  color:#fff;
  font-size: 0.8rem;
  margin-bottom: 5px;
}
.orgLink{
  font-weight: 600;
}
.orgLink:hover .smallWhiteTxt{
  color: orange !important;
}
.prfStatus{
  border-radius: 3px;
  padding: 7px 7px 5px 7px;
  width: 90%;
  float: right;
  background-color:#051a39;
  border: 1px solid #255389;
  margin-bottom: 10px;
}
.socialIcons i{
  color:#fff;
  font-size: 1.5rem;
}
.twitter-x{
  background-color: #fff;
  border-radius: 13px;
  position: relative;
  top:-4px;
}
.divCookies{
  align-items: baseline; background: rgb(53, 53, 53); color: white; display: flex; flex-flow: column wrap; justify-content: space-between; left: 0px; position: fixed; width: 100%; z-index: 999; margin-left: auto; right: 0px; font-size: 13px; padding: 15px 10px; bottom: 0px;
}
.txtCookies{
  flex: 0 0 0;margin: 10; margin-bottom: 0;width: 100%; margin-left: auto
}
.custCookies{
  border: 0px; border-radius: 0px; box-shadow: none;  cursor: pointer; flex: 0 0 auto; padding: 10px 15px; margin: 15px; font-size: 13px; font-weight: bold; float: right;
}
.cookiesSettings{
  background: rgb(255, 255, 255);
  color: rgb(10, 10, 10);
}
.cookiesReject{
  background: rgb(255, 255, 255);
  color: rgb(255, 0, 0);
}
.cookiesAccept{
  background: rgb(73, 164, 250);
  color: rgb(255, 255, 255);
}
.sSelect{
  font-size: 12px;
  padding: 3px;
  height: 30px !important;
}
.posR{
  position: relative;
}
.homeLngDiv{
  position: absolute;
  top:-65px;
  right: 10px
}
.homeLng{
  padding: 0;
}

.orSep {
  position: relative;
  width: 100%;
  height: 60px;
  color: #ddd;
  
  line-height: 50px;
  text-align: center;
}

.orSep::before,
.orSep::after {
  position: absolute;
  width: 45%;
  height: 1px;
  
  top: 24px;
  
  background-color: #aaa;
  
  content: '';
}

.orSep::before {
  left: 0;
}

.orSep::after {
  right: 0;
}

.btnSL{
  border: solid 1px #ddd;
  border-radius: 3px;
  background-color: #fff;
  width: 100%;
  font-size: 0.83rem;
  color:#111;
  font-weight: 600;
  background-repeat: no-repeat;
  height: 32px;
  background-position: 5%;
}
.slGoogle{
  background-image: url('https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png');
  background-size: 32px 32px;
}
.slApple{
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg');
  background-size: 20px 21px;
  padding-left: 30px;
  padding-top: 3px;
}
.refTxt{
  color: orange !important;
  cursor: pointer;
}
.text-orange{
  color: orange !important;
}
.text-red{
  color: red !important;
}
.showDiv{
  display: block;
}
.hideDiv{
  display: none;
}
.visisbleDiv{
  visibility: visible;
}
.hiddenDiv{
  visibility: hidden;
}
.fL{
  float: left;
}
.cookiesSetting{
  align-items: baseline; background: #fff; color: #333; display: flex; flex-flow: column wrap; justify-content: space-between; position: fixed; width: 60%; z-index: 999; font-size: 13px; padding: 15px 10px; top:10%; left:23%; border-radius: 10px;
}
.closeCookies{
  border: solid 1px #333; border-radius: 0px; box-shadow: none;  cursor: pointer; flex: 0 0 auto; font-size: 13px; font-weight: bold; float: right; position: relative; top:-5px;right:-35px;
}
.scrollY{
  height: 350px;
  overflow-y: scroll;
  margin-left: 15px;
}
.btnBorder{
  border:solid 1px #333;
}

/*
 CSS for the main interaction
*/
.accordion > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.accordion .content {
  overflow-y: hidden;
  height: 0;
  transition: height 0.3s ease;
}

.accordion > input[type="checkbox"]:checked ~ .content {
  height: auto;
  overflow: visible;
}

.accordion label {
  display: inline-block;
}

/*
 Styling
*/
.accordion {
  margin-bottom: 2px;
}

.accordion > input[type="checkbox"]:checked ~ .content {
  padding: 15px;
  border: 1px solid #e8e8e8;
  border-top: 0;
}

.accordion .handle {
  margin: 0;
  font-size: 1.125em;
  line-height: 1.2em;
  background: #e8e8e8;
}

.accordion label {
  color: #333;
  cursor: pointer;
  font-weight: normal;
  background: #e8e8e8;
  margin-bottom: 2px !important;
}

.accordion label.handle {
  width: 100%;
  padding: 15px 20px 8px 20px;
}

.accordion label:hover,
.accordion label:focus {
  background: #d8d8d8;
}

.accordion .handle label.handle:before {
  font-family: 'fontawesome';
  content: "\f054";
  display: inline-block;
  margin-right: 10px;
  font-size: .58em;
  line-height: 1.556em;
  vertical-align: middle;
}

.accordion > input[type="checkbox"]:checked ~ .handle label:before {
  content: "\f078";
}

.accordion {
  max-width: 65em;
}

.accordion p:last-child {
  margin-bottom: 0;
}

.switch {
  position: relative;
  width: 50px;
  height: 24px;
  float: right;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.swal-poup-wide {
  font-size: 1rem !important;
}